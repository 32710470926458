<template>
  <div v-if="batch">
    <section class="section is-main-section">
      <div class="columns">
        <div class="column">
          <div
            class="table-container"
            v-if="batch && batch.shipment && batch.shipment.length > 0">
            <h2 class="title">
              ການຂົນສົ່ງແບບກຸ່ມ

              <div class="buttons is-pulled-right">
                <b-button
                  icon-left="printer"
                  class="is-info is-small"
                  @click="gotoprint"
                >
                  ພິມ
                </b-button>
              </div>
            </h2>
            <table class="table is-bordered is-fullwidth">
              <thead>
                <tr>
                  <th rowspan="2">#</th>
                  <th rowspan="2" class="has-text-centered">ລະຫັດ</th>
                  <th rowspan="2" class="has-text-centered">ປາຍທາງ</th>
                  <th rowspan="2" class="has-text-centered">ຂໍ້ມູນເຄື່ອງຝາກ</th>
                  <th colspan="2" class="has-text-centered">ຄ່າຝາກ</th>
                  <th colspan="2" class="has-text-centered">COD</th>
                </tr>
                <tr>
                  <th class="has-text-centered">ກີບ</th>
                  <th class="has-text-centered">ບາດ</th>
                  <th class="has-text-centered">ກີບ</th>
                  <th class="has-text-centered">ບາດ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in batch.shipment" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.code }}</td>
                  <td>
                    <p>
                      ສາຂາ:
                      {{ item.branch_branchToshipment_to_branch_id.name }}, ໂທ:
                      {{ item.branch_branchToshipment_to_branch_id.tel }}
                    </p>
                    <p>
                      ຜູ້ຮັບ: {{ item.receivername }}, ໂທ:
                      {{ item.receivertel }}
                    </p>
                  </td>
                  <td>
                    <p>ປະເພດ: {{ item.parcel[0].parceltype.name }},</p>
                    <p>
                      ກວ້າງ: {{ item.parcel[0].width }}, ສູງ:
                      {{ item.parcel[0].height }}, ຍາວ:
                      {{ item.parcel[0].long }}, ໜັກ:
                      {{ item.parcel[0].weight }}
                    </p>
                  </td>
                  <td class="has-text-right">
                    {{ item.amount | formatnumber }}
                  </td>
                  <td class="has-text-right">
                    {{ item.amount_thb | formatnumber }}
                  </td>
                  <td class="has-text-right">
                    <p v-if="item.cod && item.cod[0]">
                      {{ item.cod[0].amount | formatnumber }}
                    </p>
                  </td>
                  <td class="has-text-right">
                    <p v-if="item.cod && item.cod[1]">
                      {{ item.cod[1].amount | formatnumber }}
                    </p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="4" class="has-text-right">ລວມ:</th>
                  <th class="has-text-right">
                    {{ _.sumBy(batch.shipment, "amount") | formatnumber }}
                  </th>
                  <th class="has-text-right">
                    {{ _.sumBy(batch.shipment, "amount_thb") | formatnumber }}
                  </th>
                  <th class="has-text-right">
                    {{ sumCod("LAK") | formatnumber }}
                  </th>
                  <th class="has-text-right">
                    {{ sumCod("THB") | formatnumber }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "BatchShipmentView",
  data() {
    return {
      batch: null,
    };
  },
  mounted() {
    this.batchno = this.$route.params["batchno"];
    this.get();
  },
  methods: {
    get() {
      axios.get(`shipments/getbatch/${this.batchno}`).then((res) => {
        console.log(res);
        this.batch = res;
      });
    },
    sumCod(ccy) {
      const sum = { LAK: 0, THB: 0 };
      for (let index = 0; index < this.batch.shipment.length; index++) {
        const item = this.batch.shipment[index];
        if (item.is_cod) {
          for (let i = 0; i < item.cod.length; i++) {
            const cod = item.cod[i];
            console.log("c", cod);
            sum[cod.ccy] += cod.amount;
          }
        }
      }
      return sum[ccy];
    },
    gotoprint() {
      this.$router.push({ path: `/printbatch/${this.batch.id}` });
    },
  },
};
</script>